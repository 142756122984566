<template>
  <div id="app" class="payment">
      <!-- class: fixed, mobile -->
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <div class="spinner" v-if="spin || !sdkInitialized"><div><div></div></div></div>
      <!-- <Spinner :spin="spin || !sdkInitialized" /> -->
      <!-- Header -->
      <section class="head">
        <div class="bg"></div>
        <div class="left">
          <a @click.prevent="goBack()">
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
          </a>
        </div>
        <span>{{ storeInfo.storeName }}</span>
        <div class="right"></div>
      </section>
      <!-- Footer -->
      <section class="foot">
        <button
          :class="{
            disabled: !this.consumptionData.orderAmount || checkPoints,
          }"
          @click.prevent="popConfirm()"
        >
          <span>支付</span>
          <!-- <span> NT${{ this.consumptionData.orderAmount }} </span> -->
          <span> NT${{ payableAmount }} </span>
          <!-- <img v-if="selectedPoint && selectedPoint.pointLogo" :src="selectedPoint.pointLogo" /> -->
          <span v-if="usedPoints"> + </span>
          <!-- <img v-if="usedPoints" src="@/assets/icon/pointW.svg"> -->
          <img v-if="usedPoints" :src="selectedPoint.pointLogoW" />
          <span v-if="usedPoints">{{ usedPoints }} </span>
        </button>
      </section>
      <!-- Main -->
      <section class="main">
        <section class="account">
          <div>
            <span v-if="this.user && this.user.displayName">{{this.user.displayName}} 正在付款</span>
            <span v-if="expiredTime">請於{{expiredTime}}前完成付款</span>
          </div>
        </section>
        <!-- 消費金額 -->
        <section class="spending">
          <span class="title">消費金額</span>
          <div class="input">
            <span>NT$</span>
            <input
              ref="orderAmount"
              type="number"
              placeholder=""
              v-model.number="consumptionData.orderAmount"
              pattern="^//d+$"
              @input="onlyNumber"
            />
          </div>
        </section>
        <!-- 點數 -->
        <AvailablePointList
          :store-code="storeCode"
          :show-toggle-all="true"
          :point-amount="consumptionData.amount"
          :toggle-all="toggleAllPoints"
          @selected="onPointSelected"
          @amountChanged="onPointAmountChange"
          @toggleAll="onToggleAllPoints"
        />
        <!-- 付款方式 -->
        <AvailablePaymentMethods 
          @selected="onPaymentMethodSelected"
          @showSystemNotice="popSystemNotice" />
      </section>
      <!-- 訊息popup -->
      <section id="popNotice" class="pop notice">
        <div class="popBody">
          <div>
            <div class="notice-close icon">
              <font-awesome-icon v-if="noticeType==='success'" icon="fa-regular fa-circle-check" size="5x" />
              <font-awesome-icon v-else-if="noticeType==='error'" icon="fa-regular fa-circle-xmark" size="5x" />
              <div v-else class="orange">
                <font-awesome-icon icon="fa-solid fa-exclamation" size="3x" />
              </div>
            </div>
            <span class="title">{{ noticeTitle }}</span>
            <span>{{ noticeMessage }}</span>
          </div>
          <button class="notice-submit">
            <span>確認</span>
          </button>
        </div>
      </section>
      <!-- 確認popup -->
      <section
        id="popConfirm"
        class="pop confirm"
        @click.prevent="popConfirmClose"
      >
        <div class="popBody">
          <span class="title">即將支付</span>
          <div>
            <span class="cost">NT${{ consumptionData.orderAmount }}</span>
            <div v-if="usedPoints">
              <font-awesome-icon v-if="payableAmount && selectedPaymentMethod.paymentService==='offline'" icon="fa-solid fa-money-bill-wave"  />
              <img v-else-if="payableAmount && selectedPaymentMethod.icon" :src="selectedPaymentMethod.icon" />
              <font-awesome-icon v-else-if="payableAmount" icon="fa-solid fa-credit-card" />
              <span v-if="payableAmount"> NT${{ payableAmount }} </span>
              <span v-if="payableAmount && usedPoints">+</span>
              <img :src="selectedPoint.pointLogo" />
              <span>{{ usedPoints }} </span>
            </div>
          </div>
          <div>
            <span class="subTitle">給</span>
            <span class="title">{{ storeInfo.storeName }}</span>
          </div>
          <div class="buttons">
            <button @click.prevent="popConfirmClose">
              <span>取消</span>
            </button>
            <button @click.prevent="popConfirmSubmit">
              <span>確定送出</span>
            </button>
          </div>
        </div>
      </section>
      <!-- 信用卡popup -->
      <section ref="popCredit" id="popCredit" class="pop credit">
        <div class="popBody">
          <a class="close">
            <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
          </a>
          <!-- <span class="title">信用卡</span>
          <div class="list">
            <a class="active">
              <span>富邦</span>
              <span>**** **** **** 1234</span>
              <img src="@/assets/icon/cVisa.svg" />
            </a>
            <a>
              <span>富邦</span>
              <span>**** **** **** 1234</span>
              <img src="@/assets/icon/cMaster.svg" />
            </a>
            <a>
              <span>富邦</span>
              <span>**** **** **** 1234</span>
              <img src="@/assets/icon/cJcb.svg" />
            </a>
          </div> -->
          <div class="new">
            <span class="title">信用卡</span>
            <div>
              <span>卡號</span>
              <div id="input-card-number" class="tpfield"></div>
            </div>
            <div>
              <span>到期日</span>
              <div id="input-exp-date" class="tpfield"></div>
            </div>
            <div>
              <span>CVC / CVV</span>
              <div id="input-ccv" class="tpfield"></div>
            </div>
          </div>
          <!-- <div class="new">
            <span class="title">新增信用卡</span>
            <div>
              <span>卡號</span>
              <input type="text" placeholder="" value="" />
            </div>
            <div class="row">
              <div>
                <span>到期日</span>
                <input type="text" placeholder="" value="" />
              </div>
              <div>
                <span>CVC / CVV</span>
                <input type="text" placeholder="" value="" />
              </div>
            </div>
            <div>
              <span>卡片上姓名</span>
              <input type="text" placeholder="" value="" />
            </div>
            <div>
              <span>卡片暱稱 (非必填)</span>
              <input type="text" placeholder="" value="" />
            </div>
            <label class="check">
              <input type="checkbox" name="checkbox2" checked />
              <span class="checkmark checkbox"></span>
              <span>儲存信用卡</span>
            </label>
          </div> -->
          <div class="buttons">
            <button @click="popPaymentTypeClose">
              <span>取消</span>
            </button>
            <button @click="popPaymentTypeClose">
              <span>確定</span>
            </button>
          </div>
        </div>
      </section>
      <!-- 訊息popup For 系統通知 -->
      <section id="popSystemNotice" class="pop notice">
        <div class="popBody">
          <div>
            <div class="notice-close icon">
              <div class="orange">
                <font-awesome-icon icon="fa-solid fa-exclamation" size="3x" />
              </div>
            </div>
            <span class="title">系統公告</span>
            <span v-html="systemNotice.message"></span>
          </div>
          <button class="notice-submit">
            <span>確認</span>
          </button>
        </div>
      </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment-timezone';
// import Spinner from '@/components/Spinner';
import AvailablePointList from '@/components/AvailablePointList';
import AvailablePaymentMethods from '@/components/AvailablePaymentMethods';

import DDPG from '@/mixins/ddpg';
import { parseErrorCode } from '@/utils/utils';

export default {
  name: 'Payment',
  mixins: [DDPG],
  components: {
    // Spinner,
    AvailablePointList,
    AvailablePaymentMethods,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      consumptionData: {
        // use: [],
        orderAmount: null, // 訂單金額
        amount: null, // 點數折抵金額
      },
      token: this.$route.query.token,
      storeCode: this.$route.query.storeCode,
      //   storeInfo: {
      //     storeId: '',
      //     storeName: '',
      //     resourceEntityType: '',
      //     resourceEntityId: '',
      //   },
      spin: false,
      isProcessing: false,
      orderInfo: null,
      selectedPoint: null, //選擇使用的點數
      noticeMessage: null,
      noticeTitle: null,
      noticeType: 'warning',
      toggleAllPoints: false,
      selectedPaymentMethod: {},
      lastErrorRequestId: null,
      showCreditCardForm: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (!to.query.token) {
        // 沒有 token
        next('/consumption-error');
      }
      if (that.user && that.user.userId) {
        // 確認是否在付款中，是否過期 & token是否存在
        that
          .getOrderInfoByToken(to.query.token)
          .then((getOrderInfoByTokenRes) => {
            console.log(
              'getOrderInfoByTokenRes:' + JSON.stringify(getOrderInfoByTokenRes)
            );
            that.orderInfo = { ...getOrderInfoByTokenRes.data };
            /* if (that.orderInfo.shopId) {
              that.initDDPG();
            } */
          })
          .catch((err) => {
            console.error('failed to get order info by token', err);
            next('/consumption-error');
          });
      } else {
        next('/error');
      }
    });
  },
  // created: function () {
  //   console.log('payment created');
  //   // this.getUserPoints()
  //   //   .then((res) => {
  //   //     this.$store.commit('updateUserPoints', res);
  //   //     this.points = JSON.parse(JSON.stringify(this.userPoints));
  //   //     console.log('this.points: ', this.points);
  //   //     this.$store.commit('payment/updateUserPoints', res);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log('err: ', err);
  //   //   });
  //   // this.getAppointedStoreInfo()
  //   //   .then((res) => {
  //   //     console.log('getAppointedStoreInfoRes: ', res);
  //   //     Object.assign(this.storeInfo, res);
  //   //     this.$store.commit('payment/updateSupportedPointTypes', res.points);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log('err: ', err);
  //   //     this.$router.push('/error');
  //   //   });
  // },
  mounted: function () {
    console.log('mount success!', this.$refs, this.$refs.orderAmount);
    this.getSystemNotice();
    // 本機測試時，如果修改程式，頁面不會reload，此時orderInfo會是null，DDPG SDK也不會init
    if (!this.orderInfo && this.token) {
      this.getOrderInfoByToken(this.token)
        .then((getOrderInfoByTokenRes) => {
          console.log(
            'getOrderInfoByTokenRes:' + JSON.stringify(getOrderInfoByTokenRes)
          );
          this.orderInfo = { ...getOrderInfoByTokenRes.data };
        })
        .catch((err) => {
          console.error('failed to get order info by token', err);
          this.$router.push('/consumption-error');
        });
    }
    this.$nextTick(function(){
      this.$refs.orderAmount.focus();
    });
  },
  watch: {
    'consumptionData.orderAmount': function () {
      // 若訂單金額修正，最多可使用的點數也可能會需要變動
      if (this.maxPointAmount < this.consumptionData.amount) {
        this.consumptionData.amount = this.maxPointAmount;
      }
      if (
        this.toggleAllPoints &&
        this.consumptionData.amount !== this.maxPointAmount
      ) {
        this.consumptionData.amount = this.maxPointAmount;
      }
    },
    'consumptionData.amount': function () {
      if (
        this.toggleAllPoints &&
        this.consumptionData.amount !== this.maxPointAmount
      ) {
        this.toggleAllPoints = false;
      }
    },
    'orderInfo.shopId': function (newVal) {
      if (newVal) {
        this.initDDPG();
      }
    },
    'sdkInitialized': function (newVal) {
      if (newVal) {
        if (this.systemNotice?.message){
          this.popSystemNotice();
        }
      }
    },
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapGetters({
      userPoints: 'getTyUserPoints',
      storeInfo: 'payment/getStoreInfo',
      // isTokenExpired: 'payment/getIsTokenExpired',
      availablePointTypes: 'payment/getAvailablePointTypes',
      appliablePointTypes: 'payment/getApplicablePointTypes',
      isDDPGInitDone: 'payment/getIsDDPGInitDone',
      systemNotice: 'payment/getSystemNotice',
    }),
    checkPoints() {
      return this.selectedPoint && this.selectedPoint.pointBalance
        ? this.selectedPoint.pointBalance < this.usedPoints
        : false;
    },
    payableAmount() {
      // 扣除點數折抵後的應付金額
      // let pointAmount = this.unmaskedPointAmount;
      return this.consumptionData.orderAmount - this.consumptionData.amount;
    },
    maxPointAmount() {
      return (
        Math.min(
          this.selectedPoint.redeemableDollars,
          this.consumptionData.orderAmount
        ) || 0
      );
    },
    usedPoints() {
      let points =
        this.selectedPoint && this.selectedPoint.pointsToCurrency
          ? (this.consumptionData.amount /
              this.selectedPoint.pointsToCurrency.currency) *
            this.selectedPoint.pointsToCurrency.points
          : this.consumptionData.amount;
      return points;
    },
    expiredTime() {
      return this.orderInfo && this.orderInfo.expirationTs
        ? moment.unix(this.orderInfo.expirationTs).format('MM/DD HH:mm')
        : null;
    },
    // showCreditCardForm() {
    //   // tappay
    //   let types = ['direct', 'credit'];
    //   let showCardForm = false;
    //   let found = null;
    //   if (this.selectedPaymentMethod.paymentType) {
    //     found = types.find((t) => t === this.selectedPaymentMethod.paymentType);
    //   }
    //   if (this.selectedPaymentMethod.paymentService === 'tappay' && found) {
    //     showCardForm = true;
    //   }
    //   // if (showCardForm) this.popPaymentType();
    //   return showCardForm;
    // },
  },
  methods: {
    initDDPG() {
      let config = {
        mode:
          process.env.VUE_APP_ENV === 'production' ||
          process.env.VUE_APP_ENV === 'beta'
            ? 'production'
            : 'sandbox',
        appId: process.env.VUE_APP_DDPG_APP_ID,
        appKey: process.env.VUE_APP_DDPG_APP_KEY,
        tappayAppId: 12867,
        tappayAppKey:
          'app_8rd2JX9GLGqoNPZPo07DI9XJP1n2X6klgOJZj5Y8eHggDTIjm44fR5UJCeJe',
        applePayMerchantId: process.env.VUE_APP_APPLE_PAY_MERCHANT_ID,
        merchantId: this.orderInfo.shopId,
        merchantName: this.orderInfo.store?.storeName,
      };
      console.debug('DDPG confg:', config);
      this.initDDPGSDK(config);
    },
    onToggleAllPoints(value) {
      console.log('onToggleAllPoints:', this.maxPointAmount);
      this.toggleAllPoints = value;
      if (this.toggleAllPoints) {
        this.consumptionData.amount = this.maxPointAmount;
      } else {
        this.consumptionData.amount = 0;
      }
    },
    onPointSelected(point) {
      // console.log('onPointSelected:' + JSON.stringify(point));
      this.selectedPoint = Object.assign({}, point);
    },
    onPaymentMethodSelected(data) {
      // console.log('onPaymentMethodSelected:' + JSON.stringify(method));
      this.selectedPaymentMethod = data.method;
      this.showCreditCardForm = data.showCardForm;
      if (data.showCardForm) {
        this.popPaymentType();
      }
    },
    onPointAmountChange(value) {
      // console.log('[payment] onPointAmountChange:' + value );
      this.consumptionData.amount = value;
      this.$nextTick(function () {
        if (value > this.maxPointAmount) {
          this.consumptionData.amount = this.maxPointAmount;
        } else {
          this.consumptionData.amount = value;
        }
      });
    },
    popPaymentType() {
      var popPaymentType = document.getElementById('popCredit');
      if (!popPaymentType) {
        return;
      }
      var close = popPaymentType.getElementsByClassName('close')[0];
      var submit = popPaymentType.getElementsByClassName('submit')[0];
      close.onclick = function () {
        popPaymentType.style.display = 'none';
      };
      if (submit) {
        submit.onclick = function () {
          popPaymentType.style.display = 'none';
        };
      }
      window.onclick = function (event) {
        if (event.target == popPaymentType) {
          popPaymentType.style.display = 'none';
        }
      };
      popPaymentType.style.display = 'flex';
    },
    popPaymentTypeClose () {
      var popPaymentType = document.getElementById('popCredit');
      if (!popPaymentType) {
        return;
      }
      popPaymentType.style.display = 'none';
    },
    popNotice(type, message, callback) {
      this.noticeType = type || 'warning';
      this.noticeMessage = message;
      var popNotice = document.getElementById('popNotice');
      var close = popNotice.getElementsByClassName('notice-close')[0];
      var submit = popNotice.getElementsByClassName('notice-submit')[0];
      close.onclick = function () {
        popNotice.style.display = 'none';
      };
      submit.onclick = function () {
        popNotice.style.display = 'none';
        if (callback) {
          callback();
        }
      };
      window.onclick = function (event) {
        if (event.target == popNotice) {
          popNotice.style.display = 'none';
        }
      };
      popNotice.style.display = 'flex';
    },
    // 檢查訂單資訊 & 付款方式
    async popConfirm() {
      this.spin = true;
      if (moment().diff(moment.unix(this.orderInfo.expirationTs)) > 0) {
        this.popNotice('warning', '付款連結已失效，請重新掃碼付款。', this.rePay);
        this.spin = false;
        return;
      }

      if (
        this.payableAmount > 0 &&
        !this.selectedPaymentMethod.paymentService
      ) {
        this.popNotice('warning', '請選擇支付方式');
        this.spin = false;
        return;
      } 

      // 選擇信用卡支付者，需先確定信用卡欄位是否填寫
      const creditCardArray = ['tappay-direct', 'tappay-credit'];
      const paymentMethod =  this.selectedPaymentMethod.paymentService + '-' +  this.selectedPaymentMethod.paymentType;
      if (creditCardArray.includes(paymentMethod)) {
        let cardFormStatus = this.getCardFormStatus();
        if (!cardFormStatus.canGetPrime) {
          this.popNotice('warning', '信用卡資訊不正確，請確認後重新付款');
          this.spin = false;
          return;
        }
      }

      if (this.consumptionData.amount > this.consumptionData.orderAmount) {
        this.popNotice('warning', '使用點數大於訂單金額');
        this.spin = false;
        return;
      }
      

      if (!this.checkBalance()) {
        // this.selectedPoint.pointBalance >= this.consumptionData.amount
        this.popNotice('warning', this.$i18n.t('Insufficient Points'));
        this.spin = false;
        return;
      }
      
      // Apple pay的話，要先init apple pay
      const applePayArray = ['tappay-apple'];
      if (applePayArray.includes(paymentMethod)) {
        console.log('trying to init apple pay');
        try {
          let initApplePayResult = await this.initApplePay(this.payableAmount);
          console.log('initApplePayResult:', initApplePayResult);
          this.spin = false;
        } catch (err) {
          console.error(`sdk.TapPay.initApplePay error caught`, err);
          this.popNotice('warning', '您的瀏覽器或商家設定不支援Apple Pay，請選擇其他支付方式。');
          this.spin = false;
          return;
        }
      }
      this.spin = false;
      var popConfirm = document.getElementById('popConfirm');
      popConfirm.style.display = 'flex';
    },
    popConfirmClose() {
      var popConfirm = document.getElementById('popConfirm');
      popConfirm.style.display = 'none';
    },
    popConfirmSubmit() {
      var popConfirm = document.getElementById('popConfirm');
      popConfirm.style.display = 'none';
      this.confirm();
    },

    checkBalance() {
      if (this.selectedPoint.pointBalance >= this.consumptionData.amount) {
        return true;
      } else {
        return false;
      }
    },
    // 確認付款
    confirm() {
      // spin 在confirm時擋，避免重複付點
      if (this.spin) return;
      this.spin = true;
      this.pay();
      // if (this.checkBalance()) {
      //   // this.callUsePoints();
      //   this.pay();
      // } else {
      //   this.popNotice('warning', this.$i18n.t('Insufficient Points'));
      //   this.spin = false;
      // }
    },
    // 付款
    pay() {
      if (
        this.payableAmount > 0 &&
        this.selectedPaymentMethod.paymentService !== 'offline'
      ) {
        // 先取得payment index再付款
        console.log('pay online ');
        this.payOnline();
      } else {
        console.log('pay offline ');
        this.payOffline();
      }
    },
    rePay() {
      this.$router.push('/scanner');
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAppointedStoreInfo() {
      console.log(this.storeCode);
      let that = this;
      var config = {
        method: 'get',
        url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${this.storeCode}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          that.$router.push('/home');
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    getOrderInfoByToken(token) {
      var config = {
        method: 'get',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders/${token}`,
        headers: {},
      };

      return this.$http(config);
    },
    payOffline() {
      console.log('pay offline');
      var data = {
        memberId: this.userInfo.memberId,
        shopId: this.storeInfo.payment?.shopId,
        points: null,
        payment: null,
        billingInfo: {
          name: this.userInfo.name,
          phone: this.userInfo.phoneNumber || "0987654321",
          email: this.userInfo.email || "service@dotdot.cc"
        },
        orderInfo: {
          amount: this.consumptionData.orderAmount,
        },
      };
      if (this.usedPoints) {
        data.points = {
          pointCode: this.selectedPoint.pointCode,
          pointName: this.selectedPoint.pointName,
          points: this.usedPoints,
          amount: this.consumptionData.amount,
          pointsToCurrency: this.selectedPoint.pointsToCurrency,
        };
      }
      this.callPayApi(data);
    },
    // 線上付款
    payOnline() {
      console.log('pay online');
      let paymentMethod = {
        paymentService: this.selectedPaymentMethod.paymentService,
        paymentType: this.selectedPaymentMethod.paymentType,
        amount: this.payableAmount,
      };
      this.spin = true;
      console.log('start get pay index');
      this.getPayIndex(paymentMethod)
        .then((res) => {
          console.log('getPayIndex done', res);
          var data = {
            memberId: this.userInfo.memberId,
            shopId: this.storeInfo.payment?.shopId,
            points: null,
            payment: {
              index: res,
              amount: this.payableAmount,
            },
            billingInfo: {
              name: this.userInfo.name,
              phone: this.userInfo.phoneNumber || "0987654321",
              email: this.userInfo.email || "service@dotdot.cc"
            },
            orderInfo: {
              amount: this.consumptionData.orderAmount,
            },
          };
          if (this.usedPoints) {
            data.points = {
              pointCode: this.selectedPoint.pointCode,
              pointName: this.selectedPoint.pointName,
              points: this.usedPoints,
              amount: this.consumptionData.amount,
              pointsToCurrency: this.selectedPoint.pointsToCurrency,
            };
          }
          this.callPayApi(data)
          .catch(e => {
            console.og('callPayApi failed', e)
          });
        })
        .catch((e) => {
          console.error('getPayIndex failed', e);
          let message = e.response?.data?.error?.message || e.response?.data?.message || e.msg; 
          
          this.popNotice('error', message || '付款失敗，請稍後重試');
          this.spin = false;
        });
    },

    callPayApi(data) {
      var config = {
        method: 'post',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders/${this.token}/pay`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      this.spin = true;
      return this.$http(config)
        .then((response) => {
          this.spin = false;
          console.log(JSON.stringify(response.data));
          //  使用replace，避免按手機回上一頁時，跳到付款頁
          if (response.data && response.data.redirectUrl) {
            window.location.href = response.data.redirectUrl;
            // 這裡沒有設定的話，按上一頁回來時，loader不會結束
            this.spin = false;
          } else {
            this.$router.replace({
              path: '/consumption-result',
              query: { orderId: response.data.orderId },
            });
            this.spin = false;
          }
        })
        .catch((e) => {
          console.log(e.response);
          let code = e.response?.data?.error?.code;
          let message = e.response?.data?.error?.message || e.response?.data?.message; 
          if (code) {
            message = parseErrorCode(code);
          }
          if (!message) {
            message = '付款失敗，請稍後重試';
          }
          const self = this;
          switch (code) {
            case 'OR002' :
              this.popNotice('warning', message, function(){
                self.$router.push('/scanner');
              });
              break;
            case 'OR005' :
              this.lastErrorRequestId = e.response?.data?.error?.meta?.requestId;
              message =
                '您有尚未完成的付款交易，若確認要繼續付款，正在進行中的付款交易將被取消；若進行中的交易有選擇使用點數或優惠券，使用的點數或優惠券將會被退回。';
              this.popNotice('warning', message, this.rollback);
              break;
            default:
              this.popNotice('error', message);
              break;
          }
          // if (code === 'OR005') {
          //   this.lastErrorRequestId = e.response?.data?.error?.meta?.requestId;
          //   message =
          //     '您有尚未完成的付款交易，若確認要繼續付款，正在進行中的付款交易將被取消；若進行中的交易有選擇使用點數或優惠券，使用的點數或優惠券將會被退回。';
          //   this.popNotice('warning', message, this.rollback);
          // } else {
          //   this.popNotice('error', message);
          // }
          this.spin = false;
        });
    },
    rollback() {
      const requestId = this.lastErrorRequestId || this.order.requestId;
      console.log(`rollback requestId ${requestId}`);
      var config = {
        method: 'post',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/orders/${this.token}/requests/${requestId}/rollback`,
        headers: {
          'Content-Type': 'application/json',
        },
      };
      this.$http(config)
        .then((res) => {
          console.log(`done rollback ${requestId} `);
          console.log(res);
          this.$store.commit('payment/updateOrderInfo', res.data);
          this.getUserPoints();
        })
        //rollback後，直接重新付款（這樣的話UI上顯示的可能是上次付款請求後被圈存完的點數）
        .finally(() => {
          this.pay();
        });
    },
    onlyNumber ($event) {
      // keypress 方式都無法避免手機上輸入小數點....
      // console.log($event); //keyCodes value
      // let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // if ($event.code === 'Period') {
      //   $event.preventDefault();
      // } else if (keyCode >= 48 && keyCode <= 57) {
      //   console.log('number');
      //   return true;
      // } else if (keyCode >= 96 && keyCode <= 105) { // 46 is dot
      //   console.log('numberPad');
      //   return true;
      // } else {
      //   $event.preventDefault();
      // }
      // let key = ($event.key ? $event.key : $event.which);
      // if (!/^[0-9]+$/.test(key)) {
      //   return $event.preventDefault();
      // }
      const val = $event.target.value.replace(/[^\d]/g, ""); // strips letters, keeps numbers
      // val.replace(/./g, "");
      // console.log('val:', val, this.consumptionData.orderAmount)
      if (val === this.consumptionData.orderAmount) {
        $event.target.value = Number(val);
      } else {
        this.consumptionData.orderAmount = Number(val);
      }
    },
    getSystemNotice() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/payment/v1/system-notice`,
        headers: {
          'Content-Type': 'application/json',
        },
      };
      this.$http(config)
        .then((res) => {
          console.log(`done get payment system notice`);
          this.$store.commit('payment/updateSystemNotice', res.data);
          // this.systemNotice = res.data;
          // if (this.systemNotice.message) this.popSystemNotice();
        })
    },
    popSystemNotice() {
      var popNotice = document.getElementById('popSystemNotice');
      var close = popNotice.getElementsByClassName('notice-close')[0];
      var submit = popNotice.getElementsByClassName('notice-submit')[0];
      close.onclick = function () {
        popNotice.style.display = 'none';
      };
      submit.onclick = function () {
        popNotice.style.display = 'none';
        // if (callback) {
        //   callback();
        // }
      };
      window.onclick = function (event) {
        if (event.target == popNotice) {
          popNotice.style.display = 'none';
        }
      };
      popNotice.style.display = 'flex';
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>